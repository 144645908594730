import authHeader from './auth-header'

import axios from 'axios'

const $http = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
})

const API_URL = '/profiles/'

class ProfilesService {
  /**
   * Get paginated profiles
   *
   * @param {Object} data
   *                 data.itemsPerPage {Number} Limit items per page
   *                 data.page {Number} Current page number
   *                 data.sortBy {Array} Sorts order
   *                 data.sortDesc {Array} If sorts must be descending
   *
   * @returns {Object} response:
   *                   response.data.profiles {Object} Profiles
   *                   response.data.totalProfiles {Object} Total profiles
   */
  getProfiles (data) {
    return $http.get(API_URL + 'all', { headers: authHeader(), params: data })
  }
}

export default new ProfilesService()
