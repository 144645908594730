<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <base-material-card
      hover-reveal
      color="primary"
      class="px-5 py-3"
    >
      <template v-slot:reveal-actions>
        <v-tooltip bottom>
          <template v-slot:activator="{ attrs, on }">
            <v-btn
              v-bind="attrs"
              color="light"
              icon
              v-on="on"
              @click="getProfiles()"
            >
              <v-icon
                color="light"
              >
                mdi-refresh
              </v-icon>
            </v-btn>
          </template>

          <span>Mettre à jour</span>
        </v-tooltip>
      </template>
      <template v-slot:heading>
        <div class="display-2 font-weight-light">
          Profils du Scraper
        </div>
      </template>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="profiles"
          :loading="loading"
          loading-text="Chargement... veuillez patienter"
          no-data-text="Pas de données disponibles"
          :options.sync="optionsProfiles"
          :server-items-length="totalProfiles"
          :search="search"
          :multi-sort="true"
          :footer-props="{
            itemsPerPageText: 'Lignes par page:',
          }"
        >
          <template v-slot:top>
            <v-text-field
              v-model="search"
              clearable
              label="Rechercher"
              class="mx-4"
            />
          </template>

          <template v-slot:item.params.mots="{ item }">
            <v-tooltip
              v-if="item.error"
              bottom
            >
              <template v-slot:activator="{ attrs, on }">
                <v-icon
                  v-bind="attrs"
                  color="red"
                  v-on="on"
                >
                  mdi-alert
                </v-icon>
              </template>

              <span>{{ item.error }}</span>
            </v-tooltip>
            {{ item.params.mots }}
          </template>
          <template v-slot:item.updatedAt="{ item }">
            <span v-if="item.updatedAt">{{ moment(item.updatedAt).calendar() }}</span>
          </template>
          <template v-slot:item.downloadedAt="{ item }">
            <span v-if="item.downloadedAt">{{ moment(item.downloadedAt).calendar() }}</span>
          </template>
          <template v-slot:item.maj="{ item }">
            <span v-if="item.maj">{{ moment(item.maj).calendar() }}</span>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="profileDetails(item)"
            >
              mdi-eye
            </v-icon>
          </template>
        </v-data-table>
      </v-card-text>
    </base-material-card>
    <base-material-overlay-alert
      v-model="alertError"
      :type="alertType"
    >
      {{ alertErrorText }}
    </base-material-overlay-alert>
    <base-confirm ref="confirm" />
    <v-dialog
      v-model="dialog"
    >
      <v-card>
        <v-card-title
          v-if="dialogItem.nom || dialogItem.prenom"
          class="headline"
        >
          {{ dialogItem.prenom + ' ' + dialogItem.nom }}
        </v-card-title>

        <v-card-text class="pa-10">
          <div
            v-for="(value, key) in dialogItem"
            :key="value._id"
          >
            {{ key }}: <b>{{ value }}</b>
          </div>
        </v-card-text>

        <v-card-actions>
          <v-spacer />

          <v-btn
            text
            @click="dialog = false"
          >
            Fermer
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
  import _ from 'lodash'
  import ProfilesService from '@/services/profiles.service'

  import moment from 'moment'
  moment.locale('fr')

  export default {
    name: 'DashboardProfile',
    data: () => ({
      alertType: 'error',
      alertError: false,
      alertErrorText: 'Une erreur est survenue',
      optionsProfiles: {
        search: null,
      },
      totalProfiles: 0,
      loading: false,
      search: null,
      headers: [
        {
          sortable: true,
          text: 'Id Toit',
          value: '_id',
        },
        {
          sortable: true,
          text: 'Id LeHibou',
          value: 'bbId',
          align: 'right',
        },
        {
          sortable: true,
          text: 'Email',
          value: 'email',
        },
        {
          sortable: true,
          text: 'Prenom',
          value: 'prenom',
        },
        {
          sortable: true,
          text: 'Nom',
          value: 'nom',
        },
        {
          sortable: true,
          text: 'Dernier mis à jour',
          value: 'updatedAt',
          align: 'right',
        },
        {
          sortable: true,
          text: 'Date de téléchargement du CV',
          value: 'downloadedAt',
          align: 'right',
        },
        {
          sortable: true,
          text: 'Toit mis à jour',
          value: 'maj',
          align: 'right',
        },
        {
          text: '',
          value: 'actions',
          sortable: false,
        },
      ],
      profiles: [],
      dialog: false,
      dialogItem: {},
    }),
    watch: {
      optionsProfiles: {
        handler () {
          this.getProfiles()
        },
        deep: true,
      },
      search: {
        handler () {
          this.debouncedGetProfiles()
        },
      },
    },
    created: function () {
      this.debouncedGetProfiles = _.debounce(this.setSearch, 700)
    },
    mounted () {
      this.getProfiles()
      this.$root.$confirm = this.$refs.confirm.open
    },
    methods: {
      moment,
      setSearch () {
        this.optionsProfiles.search = this.search
      },
      profileDetails (item) {
        this.dialogItem = item
        this.dialog = true
      },
      getProfiles () {
        this.loading = true
        ProfilesService.getProfiles(this.optionsProfiles).then(response => {
          this.profiles = response.data.profiles
          this.totalProfiles = response.data.totalProfiles
        })
          .finally(() => {
            this.loading = false
          })
          .catch(error => {
            const message =
              (error.response && error.response.data && error.response.data.message) ||
              error.message ||
              error.toString()
            if (error.response && error.response.data && error.response.data.type) {
              this.alertType = error.response.data.type
            } else {
              this.alertType = 'error'
            }
            this.alertErrorText = message
            this.alertError = true
          })
      },
    },
  }
</script>
