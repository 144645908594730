var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"user-profile","fluid":"","tag":"section"}},[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"hover-reveal":"","color":"primary"},scopedSlots:_vm._u([{key:"reveal-actions",fn:function(){return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"light","icon":""},on:{"click":function($event){return _vm.getProfiles()}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"light"}},[_vm._v(" mdi-refresh ")])],1)]}}])},[_c('span',[_vm._v("Mettre à jour")])])]},proxy:true},{key:"heading",fn:function(){return [_c('div',{staticClass:"display-2 font-weight-light"},[_vm._v(" Profils du Scraper ")])]},proxy:true}])},[_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.profiles,"loading":_vm.loading,"loading-text":"Chargement... veuillez patienter","no-data-text":"Pas de données disponibles","options":_vm.optionsProfiles,"server-items-length":_vm.totalProfiles,"search":_vm.search,"multi-sort":true,"footer-props":{
          itemsPerPageText: 'Lignes par page:',
        }},on:{"update:options":function($event){_vm.optionsProfiles=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"clearable":"","label":"Rechercher"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.params.mots",fn:function(ref){
        var item = ref.item;
return [(item.error)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var attrs = ref.attrs;
        var on = ref.on;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"}},'v-icon',attrs,false),on),[_vm._v(" mdi-alert ")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.error))])]):_vm._e(),_vm._v(" "+_vm._s(item.params.mots)+" ")]}},{key:"item.updatedAt",fn:function(ref){
        var item = ref.item;
return [(item.updatedAt)?_c('span',[_vm._v(_vm._s(_vm.moment(item.updatedAt).calendar()))]):_vm._e()]}},{key:"item.downloadedAt",fn:function(ref){
        var item = ref.item;
return [(item.downloadedAt)?_c('span',[_vm._v(_vm._s(_vm.moment(item.downloadedAt).calendar()))]):_vm._e()]}},{key:"item.maj",fn:function(ref){
        var item = ref.item;
return [(item.maj)?_c('span',[_vm._v(_vm._s(_vm.moment(item.maj).calendar()))]):_vm._e()]}},{key:"item.actions",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.profileDetails(item)}}},[_vm._v(" mdi-eye ")])]}}])})],1)],1),_c('base-material-overlay-alert',{attrs:{"type":_vm.alertType},model:{value:(_vm.alertError),callback:function ($$v) {_vm.alertError=$$v},expression:"alertError"}},[_vm._v(" "+_vm._s(_vm.alertErrorText)+" ")]),_c('base-confirm',{ref:"confirm"}),_c('v-dialog',{model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[(_vm.dialogItem.nom || _vm.dialogItem.prenom)?_c('v-card-title',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.dialogItem.prenom + ' ' + _vm.dialogItem.nom)+" ")]):_vm._e(),_c('v-card-text',{staticClass:"pa-10"},_vm._l((_vm.dialogItem),function(value,key){return _c('div',{key:value._id},[_vm._v(" "+_vm._s(key)+": "),_c('b',[_vm._v(_vm._s(value))])])}),0),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" Fermer ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }